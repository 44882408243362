import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import how_we_do_it from "../../assets/image/png/how_we_do_it.png";

import GlobalContext from "../../context/GlobalContext";
import { Section, Title, Text, ButtonIcon, Box } from "../../components/Core";

const SectionStyled = styled(Section)`
  &::before {
    opacity: 0.085;
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
  }
`;

const How = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <SectionStyled
        hero
        className="position-relative"
        pt={["50px", null, "75px", "100px"]}
        pb={["100px", null, "150px", "200px"]}
      >
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="7">
              <div className="text-center my-5">
                <Title>How we do it?.</Title>
                <br />
                <Text
                    variant="p"
                    css={`
                      max-width: 750px;
                    `}
                  >
                    We are not a consulting firm, we are a partner that grows with you.
                    <br />
                </Text>
              </div>
            </Col>
          </Row>
          <Box className="d-flex flex-column align-items-left text-left">
            <Row>
              <Col lg="6">
                <Title variant="secSm" className="my-4">
                We've solved the problem of IT talent scarcity in large companies.{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Rather than focusing on senior shortages, we develop the potential of new talents: <br />
                  this way, we help our clients hire talents they previously overlooked: Diverse, committed, and eager to contribute to the company that gives them their first opportunity.
                </Text>
              </Col>
              <Col lg="6" className="pl-lg-4">
                  <img src={how_we_do_it} alt="how_we_do_it" className="img-fluid rounded float-end"/>
              </Col>
            </Row>
          </Box>
              <div className="text-center">
                <ButtonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Demo
                </ButtonIcon>
              </div>
        </Container>
      </SectionStyled>
    </>
  );
};

export default How;
