import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing4/Hero";
import Works from "../sections/landing4/Works";
import Contact from "../sections/landing1/Contact";
import How from "../sections/landing4/How";
// import Labs from "../sections/landing4/Labs";
// import Architecture from "../sections/landing4/Architecture";


const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero />
        <How />
        <Element name="works">
          <Works />
        </Element>

        <Contact />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
